<template>
    <page-layout-nolo title="Hi Matt">

        <v-overlay color="white" :opacity="0.70" :value="true">
            <v-container class="text-center">
                <lottie-animation path="../_/img/burger-loop.json"
                                  :width="192"
                                  :height="192" />
                <div class="nolo-h6 mt-8 mx-8 black--text">This is some example text</div>
            </v-container>
        </v-overlay>

        <v-container>

            <h4>Select comparison</h4>

            <h6>Native - rough</h6>

            <v-row>
                <v-col cols="12">
                    <v-input class="d-block fake-input">
                        <select>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                            <option select>Native select</option>
                        </select>
                    </v-input>
                </v-col>
            </v-row>

            <h6>Material</h6>

            <v-select v-model="pickedDate"
                      background-color="white" outlined
                      :items="fulfilmentDays" item-text="desc" hide-details
                      return-object label="Select"></v-select>

            <!--            <v-btn @click="handoff">Google Handoff test</v-btn>-->

            <h4>Helvetica</h4>

            <v-select v-model="pickedDate"
                      background-color="white" outlined
                      :items="fulfilmentDays" item-text="desc" hide-details
                      return-object label="Select"></v-select>

            <v-divider class="my-6"></v-divider>

            <v-text-field outlined background-color="white" v-model="something" label="something"/>

            <v-btn class="mr-4 black white--text" @click="ajaxToNolo">Ajax to nolo</v-btn>

            <v-divider class="my-6"></v-divider>

            <a :href="link">
                <v-btn>Jump to NOLO</v-btn>
            </a>

            <v-divider class="my-6"></v-divider>

            <v-btn block outlined
                   class="black white--text"
                   :disabled="loading" :loading="loading">
                BUTTON ICON
                <v-icon right small>mdi-crosshairs-gps</v-icon>
            </v-btn>

            <v-divider class="my-6"></v-divider>

            <div class="row mx-1 mb-0 black">
                <div class="text-uppercase white--text col col-8">TEXT</div>
                <div class="text-right col col-4"><a href="#/start-order/location" class="">Link</a></div>
            </div>

            <v-divider class="my-6"></v-divider>

            <v-avatar color="nolo-dark-red white--text" :size="24"
                      class="font-weight-regular mini-checkout-items nolo-p3">1
            </v-avatar>

            <v-divider class="my-6"></v-divider>

            <v-container style="width: 100px">
                <v-layout class="qty">
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                    <div style="color: var(--v-secondary-base);"
                         class="qtyText text-center font-weight-black align-self-center flex-grow-1 nolo-h5">1
                    </div>
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-layout>
            </v-container>

        </v-container>

        <v-divider class="my-6"></v-divider>

        <v-container class="helvetica2">

            <h4>FG Helvetica</h4>

            <v-select v-model="pickedDate"
                      background-color="white" outlined
                      :items="fulfilmentDays" item-text="desc" hide-details
                      return-object label="Select"></v-select>

            <v-divider class="my-6"></v-divider>

            <v-text-field outlined background-color="white" v-model="something" label="something"/>

            <v-btn class="mr-4 black white--text" @click="ajaxToNolo">Ajax to nolo</v-btn>

            <v-divider class="my-6"></v-divider>

            <a :href="link">
                <v-btn>Jump to NOLO</v-btn>
            </a>

            <v-divider class="my-6"></v-divider>

            <v-btn block outlined
                   class="black white--text"
                   :disabled="loading" :loading="loading">
                BUTTON ICON
                <v-icon right small>mdi-crosshairs-gps</v-icon>
            </v-btn>

            <v-divider class="my-6"></v-divider>

            <div class="row mx-1 mb-0 black">
                <div class="text-uppercase white--text col col-8">TEXT</div>
                <div class="text-right col col-4"><a href="#/start-order/location" class="">Link</a></div>
            </div>

            <v-divider class="my-6"></v-divider>

            <v-avatar color="nolo-dark-red white--text" :size="24"
                      class="font-weight-regular mini-checkout-items nolo-p3">1
            </v-avatar>

            <v-divider class="my-6"></v-divider>

            <v-container style="width: 100px">
                <v-layout class="qty">
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                    <div style="color: var(--v-secondary-base);"
                         class="qtyText text-center font-weight-black align-self-center flex-grow-1 nolo-h5">1
                    </div>
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-layout>
            </v-container>

        </v-container>

        <v-divider class="my-6"></v-divider>

        <v-container class="roboto">

            <h4>Roboto</h4>

            <v-select v-model="pickedDate"
                      background-color="white" outlined
                      :items="fulfilmentDays" item-text="desc" hide-details
                      return-object label="Select"></v-select>

            <v-divider class="my-6"></v-divider>

            <v-text-field outlined background-color="white" v-model="something" label="something"/>

            <v-btn class="mr-4 black white--text" @click="ajaxToNolo">Ajax to nolo</v-btn>

            <v-divider class="my-6"></v-divider>

            <a :href="link">
                <v-btn>Jump to NOLO</v-btn>
            </a>

            <v-divider class="my-6"></v-divider>

            <v-btn block outlined
                   class="black white--text"
                   :disabled="loading" :loading="loading">
                BUTTON ICON
                <v-icon right small>mdi-crosshairs-gps</v-icon>
            </v-btn>

            <v-divider class="my-6"></v-divider>

            <div class="row mx-1 mb-0 black">
                <div class="text-uppercase white--text col col-8">TEXT</div>
                <div class="text-right col col-4"><a href="#/start-order/location" class="">Link</a></div>
            </div>

            <v-divider class="my-6"></v-divider>

            <v-avatar color="nolo-dark-red white--text" :size="24"
                      class="font-weight-regular mini-checkout-items nolo-p3">1
            </v-avatar>

            <v-divider class="my-6"></v-divider>

            <v-container style="width: 100px">
                <v-layout class="qty">
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                    <div style="color: var(--v-secondary-base);"
                         class="qtyText text-center font-weight-black align-self-center flex-grow-1 nolo-h5">1
                    </div>
                    <v-btn large color="var(--v-secondary-base)" icon>
                        <v-icon large>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-layout>
            </v-container>

            <v-divider class="my-6"></v-divider>

        </v-container>

        <pre>
            {{ result }}
        </pre>

    </page-layout-nolo>

</template>

<script>

    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

    import APIDebug from "../../api/APIDebug";

    export default {
        name: "MattRoxburgh",
        data() {
            return {
                pickedDate: '',
                loading: false,
                selectItems: [
                    1, 2, 3
                ],
                something: 'blah',
                result: {
                    php_session_id: ''
                },
                fulfilmentDays: ['Today', 'Tomorrow',
                    'Never0',
                    'Never1',
                    'Never2',
                    'Never3',
                    'Never4',
                    'Never5',
                    'Never6',
                    'Never7',
                    'Never8',
                    'Never9',
                    'Never10',
                    'Never11',
                    'Never12',
                    'Never13',
                    'Never14',
                    'Never15',
                    'Never16',
                    'Never17',
                    'Never18',
                    'Never19',
                ]
            }
        },
        components: { LottieAnimation },
        methods: {
            handoff() {
                this.$store.commit('handoff/target', 'https://www.google.com');
                this.$router.push({name: 'handoff'});
            },
            ajaxToNolo() {
                const me = this;
                APIDebug.setSomething(this, this.something)
                    .then(response => {
                        me.result = response.data.data;
                    })
            }
        },
        computed: {
            link() {
                return 'https://fiveguysui.lineten.net/apiui/debug/view?nolo_session=' + this.$store.getters['session/sessionId'];
            }
        }
    }
</script>

<style scoped>

    .helvetica2 {
        font-family: Helvetica2, sans-serif;
    }

    .roboto {
        font-family: Roboto, sans-serif;
    }

    .fake-input {
        display: flex;
        background-color: white;
        height: 48px;
        background-clip: border-box;
        border: 1px solid #999;
        border-radius: 4px;
        align-content: center;
    }
    .fake-input:hover {
        border-color: var(--v-nolo-dark-grey-base);
    }
    .fake-input:focus-within {
        border-color: var(--v-primary-base);
        box-shadow: 0 0 0 1px var(--v-primary-base);
        color: var(--v-primary-base);
    }

    .fake-input >>> .v-input__slot::before {
        content: "\F0140";
        font: normal normal normal 24px/1 "Material Design Icons";
        top: 10px;
        right: 12px;
        position: absolute;
        pointer-events: none;
        transform: rotate(0deg);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    /*.fake-input:focus-within >>> .v-input__slot::before {*/
    /*    transform: rotate(180deg);*/
    /*    color: var(--v-primary-base);*/
    /*}*/

    .fake-input select {
        appearance: none;
        background-color: transparent;
        border: none;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        align-self: center;
        height: 48px;
        min-width: 100%;
        padding: 0 10px 4px 10px;
        cursor: pointer;
        outline: none;
    }

</style>
