import _ApiHelper from "./_ApiHelper";

function setSomething(vue, something) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/debug/setsomething',
                data: {
                    something: something
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            })
            .then(function (response) {
                _ApiHelper.sessionTracker(vue, response);
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}
export default {
    setSomething: setSomething,
}
